import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
const OnlineFee = () => {
  return (
     <>
     <div class="online_fee"> <Link to="/" target="_blank">
      <LazyLoad><img class="" src="https://webapi.entab.info/api/image/LVISGN/public/Images/enquire.png" alt="Lotus Valley International School, Greater Noida"/></LazyLoad></Link>
    <div class="clr10"></div>
    <Link to="https://www.lviscampuscare.net/" target="_blank">
      <LazyLoad><img class="" src="https://webapi.entab.info/api/image/LVISGN/public/Images/online_payment.gif" alt="Lotus Valley International School, Greater Noida"/></LazyLoad></Link></div>
      </>
        
  )
}

export default OnlineFee
