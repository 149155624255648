import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
import HeadTag from '../Component/HeadTags'
import LazyLoad from 'react-lazyload'
const SubjectShowcases = () => {
  return (
    <>
    <HeadTag title="Subject Showcases" />
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Academics <i className="fas fa-angle-right"></i>Subject Showcases </span></h5>
        <h2>Subject Showcases</h2>
        </div>
        <div className="row popup-gallery socialWork mt-4">
            <div className='col-md-12'>
              <h3>Literary Week - Lotus Literati</h3>
               
              <p>The literary week, Lotus Literati, at Lotus Valley International School, Noida Extension, was a vibrant celebration of language and creativity, showcasing the exceptional talents of students in Hindi, English, Sanskrit, and foreign languages.</p>
              <p>In Hindi, Grade VI students paid tribute to historical heroes in the Shaurya Ki Amar Gatha poetry recitation. Grade VII students explored the profound teachings of Bharatiya Darshan Ka Darpan: Mahabharat in a book review, while Grade VIII students showcased their creativity in the Katha Sagar group story-writing competition.</p>
              <p>The English events were equally captivating. Grade VI students engaged in a thoughtful Book Discussion on Iqbal, Grade VII participated in the Story Weaving Competition, and Grade VIII engaged in a spirited debate on contemporary issues. </p>
              <p>The week culminated in a Language Assembly celebrating the diversity of French, German, Spanish, and Sanskrit. Students enchanted the audience with performances, including a multilingual skit, a soulful Sanskrit song, a cultural quiz, and tributes to iconic literary figures from around the world.</p>
              <p>With boundless enthusiasm and linguistic brilliance, the students demonstrated the transformative power of language to unite cultures, ignite imaginations, and inspire future leaders. The Lotus Literati week left everyone in awe of the creativity and potential of these young minds, eager to see what stories they’ll continue to craft in the years to come.</p>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary1.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary2.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary3.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary4.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary5.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary6.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary7.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary8.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary9.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary10.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary11.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary12.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary13.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary14.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary15.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/literary16.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            
          </div>


        <div className="row popup-gallery socialWork mt-4">
            <div className='col-md-12'>
              <h3>Lotus Literati 2023</h3>
               
              <p>"Literature is the art of discovering something extraordinary about ordinary people, and saying with ordinary words something extraordinary.” Boris Pasternak</p>
              <p>The enchanting Literary Week, 'Lotus Literati 2023,' was suffused with literary exuberance held at Lotus Valley International School Noida Extension, from August 3 to August 9, 2023. A confluence of ideas, thoughts and discussions marked the literary fest and witnessed the convergence of educators, guests and authors for engaging sessions, enactments, poetry renditions and much more.</p>
              <p>The valedictory ceremony was graced by the renowned author Mr. Suhail Mathur as the chief guest, while esteemed members of the Managing Committee, Ms. Sureena Uppal and Ms. Jaspreet Kaur, adorned the event as the guests of honour. Principal Ms. Indu Yadav's heartfelt address illuminated the importance of literature in nurturing empathy, critical thinking, and creativity, with books serving as portals to diverse realms.</p>
              <p>The stage blossomed with Shakespearean enchantment, as characters from his timeless tales emerged, infusing the ambience with poetic splendour. A captivating discussion on Harper Lee's 'To Kill a Mockingbird' unfolded, delving into themes of courage, justice, and empathy, showcasing literature's power to instigate meaningful dialogues. The release of books authored by students and teachers provided a glimpse into the thriving creative culture in the school community. The event reverberated with a harmonious blend of languages, epitomizing unity through the melody of "Saare Jahan Se Accha."</p>
              <p>Elegance embraced the event with a graceful dance choreography synchronized to Shakespeare's Sonnet 18, exemplifying the timeless allure of his verses. A mesmerizing medley of French, German, and Spanish songs underscored the universal language of music.</p>
              <p>The stage pulsated with the emotive rendition of "Bishop's Candlesticks," its poignant narrative leaving an indelible mark. As the curtains drew close, gratitude unfurled in a heartfelt vote of thanks, acknowledging the students’ efforts and teachers’ support.</p>
              <p>Mr. Suhail Mathur shared profound insights into literature's enduring influence, accentuating the significance of nurturing youthful ardour for reading and writing. An interactive session illuminated his creative odyssey, enriching young minds.</p>
              <p>As 'Lotus Literati 2023' culminated, its echoes lingered, a testament to literature's unwavering ability to unite, inspire, and transform lives through its ethereal tapestry of words and expressions.</p>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus4.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus5.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus6.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus7.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="img-block">
               <LazyLoad> <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/lotus8.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida" /></LazyLoad>
              </div>
            </div>
            
          </div>
        <div className="row mt-4">
            <div className="col-lg-12 col-md-12">
            <p><strong>STEAM showcase</strong> was organised by the students of classes III-VIII <strong>on 22-23 October 2019</strong> in the school premises. The students integrated the disciplines of Science, Technology, Engineering, Arts & Mathematics and confidently presented innovative and interesting projects. The students worked with gusto and enthusiasm to become problem solvers by designing a Blindman’s Stick, Smart Dustbins, Smart Cities, Automated Watering Systems, Robo Soccer, Floor Cleaner, Path Finder Bot. They found STEAM in Tessellation, Fibonacci Series, Tall Structures, Toys from Trash, Hydraulic Structures. The art displays representing various artists were mesmerizingly praiseworthy. </p>

            <p><strong>TED Ed. showcase</strong> was organised by the students of <strong>grades VI-VIII on 20 February 2020</strong> after 4 months of exciting journey and mastering the art of delivering impactful TED Talks. The TED Ed. club has been created with the aim to spark and celebrate the idea & knowledge of teachers and students around the world.</p>
            
            <p><strong>Public Speaking Showcase</strong> was organised on <strong>15 February 2020</strong> where the students of <strong>Project Awaaz</strong> displayed plethora of activities and gave a glimpse of the nuances and art of public speaking and critical thinking that they learned in a year long journey.</p>
                <div className="clr"></div>	
                <h3>Subject Weeks</h3>
                    <ul>
                    <li><strong>Literary Week</strong> was organised for the <strong>students of grades III-VIII from 13-17 May, 2019.</strong> The week focussed on activities such as poetry writing, comic strip making, book cover designing and puppet show that helped the children hone their critical thinking, speaking and creative skills. At the end of the week, students showcased some of these activities before the parents. Competitions such as Spin a Yarn, English recitation, Tete-a-Tete with the Author and Hasya Kavi Sammelan enthralled the audience and kept them hooked till the end.</li>
                    <li><strong>EVS Week</strong> was organised by the students of grades <strong>I & II from 17 – 19 July, 2019.</strong> The focus of the week was to sensitize the students about the conservation of water and to reflect the history of India.  During this week, students found themselves delving into research, collaborating ideas and presenting their thoughts before one another. They participated in the activities such as collaborative poster making on ‘how to save water’, researched on the monuments of India and presented their ideas collectively in the classroom, navigated through the streets of India on Google Earth. The week ended with the students pledging to use water judiciously and spreading this message in the school through their ‘Save the Water’ drive. They also participated in ‘My Country, My Pride’ competition and represented different states of India to showcase its famous cuisines, wide number of languages, colourful accessories and its major attractions. </li>
                    <li><strong>EVS and Social Science week</strong> was celebrated from <strong>18th - 20th July 2019 for classes III-VIII.</strong> It gave an opportunity to children to get involved into a gamut of fun-learning activities enabling students to engage themselves into social science research to exhibit the diversity of various cultures and their relevance in today’s time. It emphasized on the nuances of creating and making social science a subject that goes beyond the classroom learning.</li>
                    <li>LVISNE celebrated the <strong>reading week ‘Read-a-Thon’</strong> on the occasion of International Literacy Day from <strong>9 - 15 September, 2019</strong> for all classes. The aim of the week was to highlight the need of building opportunities for individualized reading at all stages and promote a culture of reading habits among students. Students carried out various activities during this week like Book Cover Designing, Book Mark Making, Reinvention of the Stories etc. Students and teachers also took part in Book Donation Activity.</li>
                    <li>The Pre-Primary wing organised a <strong>Sports Week ‘Fit-a-Thon’</strong> for classes <strong>Pre-Nursery - II, from 4 -16 December, 2019.</strong> Students participated in various track events and were guided & encouraged by the teachers present. Loud roars of applause were heard, as students cheered for their peers. Happy faces filled the field as the prize winners stood proudly to accept their certificates on the podium.</li>
                    </ul>
                   
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default SubjectShowcases
