import React from "react";
import LazyLoad from "react-lazyload";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
const QuickGlanceEightslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
        <div className="draw-border">
        <h3><Link to="Infrastructure">Infrastructure</Link></h3>
        <p>Infrastructure is the set of fundamental facilities and systems that support the sustainable functionality of schools.</p>
        <div className="clr"></div>
      </div>
        </div>
        <div className="item">
       <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/infra.jpg" className="image img-fluid" alt="Lotus Valley International School, Greater Noida"/>
        </div>
        <div className="item">
        <div className="draw-border">
        <h3><Link to="Infrastructure">Infrastructure</Link></h3>
        <p>Infrastructure is the set of fundamental facilities and systems that support the sustainable functionality of schools.</p>
        <div className="clr"></div>
      </div>
        </div>
        <div className="item">
       <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/infra.jpg" className="image img-fluid" alt="Lotus Valley International School, Greater Noida"/>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlanceEightslide;
