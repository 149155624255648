import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
const QuickGlanceseventhslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
          <img src="https://webapi.entab.info/api/image/LVISGN/public/Images/art.jpg" className="image" alt="Lotus Valley International School, Greater Noida"/>
        </div>
        <div className="item">
        <div className="draw-border">
        <h3><Link to="AssessmentEvaluation">Examination</Link></h3>
        <p>A test or examination is an educational assessment intended to measure a test-taker's knowledge, skill, aptitude, physical fitness, or classification in many other topics.</p>
        <div className="clr"></div>
      </div>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlanceseventhslide;
